import { EnvironmentOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  Alert,
  Card,
  Col,
  Descriptions,
  Divider,
  Layout,
  List,
  Row,
  Spin,
  Steps,
  Table,
  Typography,
} from "antd";
import GoogleMapReact from "google-map-react";
import React, { Component } from "react";
import { fetchLoads } from "./api";
import "./App.css";
import generateObfuscatedHeaderText from "./helpers/generateObfuscatedHeaderText";
import getDescription from "./helpers/getDescription";
import LiveMarker from "./LiveMarker";

const { Title } = Typography;
const { Header, Content, Footer } = Layout;
const { Step } = Steps;

// const LiveMarker = () => <div className="pulseIcon"></div>;

const formatPhoneNumber = (num) => {
  if (!num) {
    return null;
  }
  let cleaned = ("" + num.slice(2)).replace(/\D/g, "");
  let area = cleaned.substring(0, 3);
  let middle = cleaned.substring(3, 6);
  let last = cleaned.substring(6, 10);

  if (cleaned.length > 6) {
    return `${area}-${middle}-${last}`;
  } else if (cleaned.length > 3) {
    return `${area}-${middle}`;
  } else if (cleaned.length > 0) {
    return `${area}`;
  } else {
    return null;
  }
};

class App extends Component {
  state = {
    locations: [],
    loading: true,
    trackingStatus: null,
  };

  vehiclesColumns = [
    {
      title: "Make",
      dataIndex: "make",
      key: "make",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Vehicle Type",
      dataIndex: "vehicleType",
      key: "vehicleType",
      render: (text, record, id) => {
        return (
          <span>
            {text === 1
              ? "Car"
              : text === 2
              ? "Truck"
              : text === 3
              ? "SUV"
              : text === 4
              ? "Van"
              : "Other"}
          </span>
        );
      },
    },
  ];

  driverColumns = [
    {
      title: "Name",
      dataIndex: "driverName",
      key: "driverName",
    },
    {
      title: "Phone Number",
      dataIndex: "driverPhoneNumber",
      key: "driverPhoneNumber",
    },
    {
      title: "Transporter Company",
      dataIndex: "entityName",
      key: "entityName",
    },
  ];

  componentDidMount = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const shipmentId = urlParams.get("shipmentId");
    if (!shipmentId) {
      window.alert("Shipment Id missing");
    } else {
      fetchLoads(shipmentId).then((data) => {
        this.setState({
          locations: data,
          loading: false,
          trackingStatus: this.getTrackingStatus(
            data[0].loadStatus,
            data[0].shipmentStatus
          ),
        });
      });
    }
  };

  extraMapRenders = (map, maps) => {
    const { locations } = this.state;

    const pickupMarkerText = `${locations[0].shipmentPickup.location.city}, ${locations[0].shipmentPickup.location.state} ${locations[0].shipmentPickup.location.zip}`;
    const dropoffMarkerText = `${locations[0].shipmentDropoff.location.city}, ${locations[0].shipmentDropoff.location.state} ${locations[0].shipmentDropoff.location.zip}`;

    let directionsService = new maps.DirectionsService();
    let directionsRender = new maps.DirectionsRenderer({
      suppressMarkers: true,
    });
    directionsRender.setMap(map);
    directionsService.route(
      {
        origin: `${locations[0].shipmentPickup.location.latitude},${locations[0].shipmentPickup.location.longitude}`,
        destination: `${locations[0].shipmentDropoff.location.latitude},${locations[0].shipmentDropoff.location.longitude}`,
        travelMode: "DRIVING",
      },
      function (response, status) {
        if (status === "OK") {
          directionsRender.setDirections(response);
        } else {
          window.alert("Directions request failed due to " + status);
        }
      }
    );

    const infoWindow = new maps.InfoWindow();

    const pickupMarker = new maps.Marker({
      position: {
        lat: locations[0].shipmentPickup.location.latitude,
        lng: locations[0].shipmentPickup.location.longitude,
      },
      map,
      label: {
        text: "A",
        color: "white",
      },
    });

    const dropoffMarker = new maps.Marker({
      position: {
        lat: locations[0].shipmentDropoff.location.latitude,
        lng: locations[0].shipmentDropoff.location.longitude,
      },
      map,
      label: {
        text: "B",
        color: "white",
      },
    });

    // Function to show InfoWindow on marker click
    const showInfoWindow = (marker, content) => {
      infoWindow.setContent(content);
      infoWindow.open(map, marker);
    };

    // Add click listeners to show custom info window
    pickupMarker.addListener("click", () => {
      showInfoWindow(pickupMarker, `<div>${pickupMarkerText}</div>`);
    });

    dropoffMarker.addListener("click", () => {
      showInfoWindow(dropoffMarker, `<div>${dropoffMarkerText}</div>`);
    });
  };

  getTrackingStatus = (loadStatus, shipmentStatus) => {
    if (loadStatus === 0) {
      return 0;
    }
    if (loadStatus === 1 || loadStatus === 2) {
      return 1;
    }
    if (
      loadStatus === 3 ||
      loadStatus === 4 ||
      loadStatus === 5 ||
      loadStatus === 6 ||
      shipmentStatus === 4
    ) {
      return 2;
    }
    return null;
  };

  translateLoadStatus = (loadStatus, shipmentStatus) => {
    if (loadStatus === 0) {
      return "Assigned to Driver";
    }
    if (loadStatus === 1) {
      return "In Transit";
    }
    if (loadStatus === 2) {
      return "Picked Up";
    }
    if (loadStatus === 3) {
      return "Delivered";
    }
    if (loadStatus === 4) {
      return "Completed";
    }
    if (loadStatus === 7) {
      return "Failed";
    }
    if (loadStatus === 8) {
      return "Canceled";
    }
    if (shipmentStatus === 4) {
      return "Delivered";
    }
    return "";
  };

  translateTimeSlot = (timeSlot) => {
    if (timeSlot === 0) {
      return "8:00 AM - 12:00 PM (estimated)";
    }
    if (timeSlot === 1) {
      return "12:00 PM - 4:00 PM (estimated)";
    }
    if (timeSlot === 2) {
      return "4:00 PM - 8:00 PM (estimated)";
    }
  };

  parseDriverTableData = (data) => {
    if (!data) return [];
    const { driver, entity } = data ?? {};

    if (!driver || !entity) {
      return [
        {
          driverName: "N/A",
          driverPhoneNumber: "N/A",
          entityName: "N/A",
        },
      ];
    }

    return [
      {
        driverName: `${driver.firstName} ${driver.lastName}`,
        driverPhoneNumber: formatPhoneNumber(driver.phoneNumber),
        entityName: `${entity.name}`,
      },
    ];
  };

  render() {
    const { locations, loading, trackingStatus } = this.state;

    return (
      <Layout>
        <Header style={{ background: "#fff" }}>
          <img src="/autosled-logo.png" alt="Autosled" style={{ width: 50 }} />
        </Header>
        <Content style={{ padding: "10px 50px" }}>
          {loading ? (
            <div
              style={{
                textAlign: "center",
                borderRadius: "4px",
                marginBottom: "20px",
                padding: "30px 50px",
                margin: "20px 0",
              }}
            >
              <Spin />
            </div>
          ) : locations[0].shipmentStatus === 2 ? (
            <Card>
              <div style={{ width: "100%", textAlign: "center" }}>
                <Title level={3}>Posted</Title>
              </div>
              <Steps
                style={{
                  marginBottom: 32,
                  justifyContent: "center",
                }}
                current={0}
              >
                <Step
                  status="process"
                  title={"Awaiting Transporter"}
                  icon={<LoadingOutlined style={{ color: "#4888FA" }} />}
                />
              </Steps>
              <Divider
                style={{
                  marginBottom: 32,
                }}
              />
              <Steps
                style={{
                  marginBottom: 32,
                }}
              >
                <Step
                  status="finish"
                  title={"Pickup"}
                  description={`${locations[0].shipmentPickup.location.city}, ${locations[0].shipmentPickup.location.state} ${locations[0].shipmentPickup.location.zip}`}
                  icon={<EnvironmentOutlined style={{ color: "#4888FA" }} />}
                />
                <Step
                  status="finish"
                  title={"Drop off"}
                  description={`${locations[0].shipmentDropoff.location.city}, ${locations[0].shipmentDropoff.location.state} ${locations[0].shipmentDropoff.location.zip}`}
                  icon={<EnvironmentOutlined style={{ color: "#DD4B3E" }} />}
                />
              </Steps>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={12}>
                  <div
                    style={{
                      marginBottom: 16,
                      color: "rgba(0,0,0,0.85)",
                      fontWeight: 500,
                      fontSize: 16,
                    }}
                  >
                    Vehicles
                  </div>
                  {/* Posted or earlier */}
                  {locations[0].shipmentStatus <= 2 && (
                    <List
                      itemLayout="horizontal"
                      dataSource={locations[0].vehicles}
                      renderItem={(item) => (
                        <List.Item>
                          <List.Item.Meta
                            title={<b>{generateObfuscatedHeaderText(item)}</b>}
                            description={getDescription(item, true)}
                          />
                        </List.Item>
                      )}
                    />
                  )}
                  {/* Assigned and later */}
                  {locations[0].shipmentStatus > 2 && (
                    <Table
                      style={{
                        marginBottom: 32,
                      }}
                      pagination={false}
                      dataSource={locations[0].vehicles}
                      columns={this.vehiclesColumns}
                      rowKey="vehicleId"
                    />
                  )}
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <div
                    style={{
                      height: "400px",
                    }}
                  >
                    <Alert
                      message="Due to new privacy laws, live GPS tracking is only available for transporters who opt-in"
                      type="info"
                      showIcon
                    />
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: "AIzaSyALpNxsbBEhvywuEc_3KIA1hKDMFOG6FOk",
                      }}
                      defaultCenter={{
                        lat: locations[0].shipmentPickup.location.latitude,
                        lng: locations[0].shipmentPickup.location.longitude,
                      }}
                      defaultZoom={10}
                      yesIWantToUseGoogleMapApiInternals
                      onGoogleApiLoaded={({ map, maps }) =>
                        this.extraMapRenders(map, maps)
                      }
                    >
                      {locations.map((load, i) => (
                        <LiveMarker
                          key={i}
                          lat={load.shipmentPickup.location.latitude}
                          lng={load.shipmentPickup.location.longitude}
                          status={
                            locations[0]?.loadStatus === 0 ? "Assigned" : ""
                          }
                        />
                      ))}
                    </GoogleMapReact>
                  </div>
                </Col>
              </Row>
              <Divider
                style={{
                  marginBottom: 32,
                  marginTop: 80,
                }}
              />
              <Descriptions column={{ xs: 1, sm: 1, md: 2 }} bordered>
                <Descriptions.Item label="Pickup ETA">
                  Pending...
                </Descriptions.Item>
                <Descriptions.Item label="Drop off ETA">
                  Pending...
                </Descriptions.Item>
                {/* <Descriptions.Item label="Shipment Distance in Miles">{locations[0].shipmentDistance.toLocaleString()}</Descriptions.Item> */}
              </Descriptions>
              <Divider
                style={{
                  marginBottom: 32,
                }}
              />
            </Card>
          ) : (
            <Card>
              <div style={{ width: "100%", textAlign: "center" }}>
                <Title level={3}>
                  {this.translateLoadStatus(
                    locations[0].loadStatus,
                    locations[0].shipmentStatus
                  )}
                </Title>
              </div>
              <Steps
                style={{
                  marginBottom: 32,
                }}
                current={trackingStatus}
              >
                <Step title="Assigned to Driver" />
                <Step title="In Transit" />
                <Step title="Delivered" />
              </Steps>
              <Divider
                style={{
                  marginBottom: 32,
                }}
              />
              <Steps
                style={{
                  marginBottom: 32,
                }}
              >
                {/* <Step status="finish"
                    title={'Pickup'}
                    description={`${locations[0].shipmentPickup.location.addressLine1} ${locations[0].shipmentPickup.location.city}, ${locations[0].shipmentPickup.location.state} ${locations[0].shipmentPickup.location.zip}`}
                    icon={<EnvironmentOutlined style={{ color: '#4888FA' }} />}
                  />
                  <Step status="finish"
                    title={'Drop off'}
                    description={`${locations[0].shipmentDropoff.location.addressLine1} ${locations[0].shipmentDropoff.location.city}, ${locations[0].shipmentDropoff.location.state} ${locations[0].shipmentDropoff.location.zip}`}
                    icon={<EnvironmentOutlined style={{ color: '#DD4B3E' }} />}
                  /> */}
                <Step
                  status="finish"
                  title={"Pickup"}
                  description={`${locations[0].shipmentPickup.location.city}, ${locations[0].shipmentPickup.location.state} ${locations[0].shipmentPickup.location.zip}`}
                  icon={<EnvironmentOutlined style={{ color: "#4888FA" }} />}
                />
                <Step
                  status="finish"
                  title={"Drop off"}
                  description={`${locations[0].shipmentDropoff.location.city}, ${locations[0].shipmentDropoff.location.state} ${locations[0].shipmentDropoff.location.zip}`}
                  icon={<EnvironmentOutlined style={{ color: "#DD4B3E" }} />}
                />
              </Steps>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={12}>
                  <div
                    style={{
                      marginBottom: 16,
                      color: "rgba(0,0,0,0.85)",
                      fontWeight: 500,
                      fontSize: 16,
                    }}
                  >
                    Vehicles
                  </div>
                  <Table
                    style={{
                      marginBottom: 32,
                    }}
                    pagination={false}
                    dataSource={locations[0].vehicles}
                    columns={this.vehiclesColumns}
                    rowKey="vehicleId"
                  />
                  <div
                    style={{
                      marginBottom: 16,
                      color: "rgba(0,0,0,0.85)",
                      fontWeight: 500,
                      fontSize: 16,
                    }}
                  >
                    Driver
                  </div>
                  <Table
                    style={{
                      marginBottom: 32,
                    }}
                    pagination={false}
                    dataSource={this.parseDriverTableData(locations[0])}
                    columns={this.driverColumns}
                    rowKey={0}
                  />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <div
                    style={{
                      height: "400px",
                    }}
                  >
                    <Alert
                      message="Due to new privacy laws, live GPS tracking is only available for transporters who opt-in"
                      type="info"
                      showIcon
                    />
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: "AIzaSyALpNxsbBEhvywuEc_3KIA1hKDMFOG6FOk",
                      }}
                      defaultCenter={{
                        lat: locations[0].loadLatitude,
                        lng: locations[0].loadLongitude,
                      }}
                      defaultZoom={10}
                      yesIWantToUseGoogleMapApiInternals
                      onGoogleApiLoaded={({ map, maps }) =>
                        this.extraMapRenders(map, maps)
                      }
                    >
                      {locations.map((load, i) => (
                        <LiveMarker
                          key={i}
                          lat={
                            load.loadLatitude === 0
                              ? load.shipmentPickup.location.latitude
                              : load.loadLatitude
                          }
                          lng={
                            load.loadLongitude === 0
                              ? load.shipmentPickup.location.longitude
                              : load.loadLongitude
                          }
                          status={
                            locations[0]?.loadStatus === 0 ? "Assigned" : ""
                          }
                        />
                      ))}
                    </GoogleMapReact>
                  </div>
                </Col>
              </Row>
              <Divider
                style={{
                  marginBottom: 32,
                  marginTop: 80,
                }}
              />
              <Descriptions column={{ xs: 1, sm: 1, md: 2 }} bordered>
                {/* <Descriptions.Item label="Pickup Contact Name">{locations[0].shipmentPickup.contactName ? locations[0].shipmentPickup.contactName : " "}</Descriptions.Item>
                  <Descriptions.Item label="Pickup Contact Phone Number">{locations[0].shipmentPickup.contactPhone ? formatPhoneNumber(locations[0].shipmentPickup.contactPhone) : " "}</Descriptions.Item>
                  <Descriptions.Item label="Drop off Contact Name">{locations[0].shipmentDropoff.contactName ? locations[0].shipmentDropoff.contactName : " "}</Descriptions.Item>
                  <Descriptions.Item label="Drop off Contact Phone Number">{locations[0].shipmentDropoff.contactPhone ? formatPhoneNumber(locations[0].shipmentDropoff.contactPhone) : " "}</Descriptions.Item> */}
                <Descriptions.Item label="Pickup ETA">
                  {locations[0]?.loadStatus >= 2 && "Pickup Confirmed"}

                  {locations[0]?.loadStatus < 2 && locations[0].pickupEta
                    ? new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "2-digit",
                      }).format(new Date(locations[0].pickupEta)) +
                      "\n" +
                      this.translateTimeSlot(locations[0].pickupEtaTimeSlot)
                    : " "}
                </Descriptions.Item>
                <Descriptions.Item label="Drop off ETA">
                  {locations[0]?.loadStatus >= 3 && "Drop off Confirmed"}

                  {locations[0]?.loadStatus < 3 && locations[0].dropoffEta
                    ? new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "2-digit",
                      }).format(new Date(locations[0].dropoffEta)) +
                      "\n" +
                      this.translateTimeSlot(locations[0].dropoffEtaTimeSlot)
                    : " "}
                </Descriptions.Item>
                {/* <Descriptions.Item label="Shipment Distance in Miles">{locations[0].shipmentDistance.toLocaleString()}</Descriptions.Item> */}
              </Descriptions>
              <Divider
                style={{
                  marginBottom: 32,
                }}
              />
            </Card>
          )}
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Copyright ©2020 Autosled
        </Footer>
      </Layout>
    );
  }
}

export default App;
