import React from "react";

const getDescription = (item, redactVehicleInfo) => {
  const normalSubText = "";
  const obfuscatedSubText = item?.vehicleObfuscate
    ? `(Ex: ${item.vehicleObfuscate.toolTip})`
    : "";

  const visibleSubText = !redactVehicleInfo ? normalSubText : obfuscatedSubText;

  return (
    <div>
      <span>{visibleSubText}</span>
    </div>
  );
};

export default getDescription;
