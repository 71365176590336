/**
 * @typedef {object} VehicleObfuscate
 * @property {bool} isLateModel
 * @property {string} vehicleType
 * @property {string} vehicleSize
 * @property {string} toolTip
 */

const generateObfuscatedHeaderText = (vehicle) => {
  /**
   * @type {VehicleObfuscate}
   */
  const vehicleObfuscate = vehicle?.vehicleObfuscate;

  if (!vehicleObfuscate) return "";

  const { isLateModel, vehicleSize, vehicleType, year } = vehicleObfuscate;

  const yearText = isLateModel ? "Late Model" : year;

  const headerText = `${yearText} ${vehicleSize} ${vehicleType}`;

  return headerText;
};

export default generateObfuscatedHeaderText;
